import {
  GetCategoriesLayoutProducts_CachedQuery,
  GetCategoriesLayoutProducts_CachedQueryVariables
} from '@data/__generated__/types.main'
import {useUserPreferences} from '@data/queries/preferences/userPreferences.main'
import hasWebpSupport from '@helpers/misc/hasWebpSupport'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery} from 'apollo-hooks'

import {getCategoriesLayoutProducts} from './queries/categoryLayoutProductsQueries.main'

export interface UseProductsNonCPGOpts {
  categoryId?: string
  storeId?: string
  deliverAt?: string
  menuId?: string
  full?: boolean
  omit?: boolean
}

export default function useProductsNonCPG({categoryId, full, omit}: UseProductsNonCPGOpts) {
  const websiteId = useWebsiteId()
  const format = hasWebpSupport() ? 'webp' : 'jpeg'
  const isInsideSelectedCategory = full

  const data = useUserPreferences()
  const {menuId, storeId, deliverAt} = data

  const variables = {
    websiteId,
    categoryId,
    menuId,
    format,
    limit: isInsideSelectedCategory ? null : 10,
    highlighted: !full,
    storeId,
    deliverAt
  }

  const {category, loading} = useApolloQuery<
    GetCategoriesLayoutProducts_CachedQuery,
    GetCategoriesLayoutProducts_CachedQueryVariables
  >({
    query: getCategoriesLayoutProducts,
    variables,
    omit: omit,
    fetchPolicy: 'cache-first'
  })

  if (omit) return []

  const categoriesProducts = category?.products
  if (loading) return []

  return categoriesProducts ?? []
}
