import productListInformationFragment from '@data/fragments/products/productListInformation.main'
import gql from 'graphql-tag'

export const getCategoriesLayoutProducts = gql`
  query getCategoriesLayoutProducts_cached(
    $websiteId: ID
    $categoryId: ID
    $menuId: ID
    $format: String
    $limit: Float
    $highlighted: Boolean
    $storeId: ID
    $deliverAt: Date
  ) {
    category(websiteId: $websiteId, categoryId: $categoryId) {
      _id
      products(
        highlighted: $highlighted
        limit: $limit
        menuId: $menuId
        storeId: $storeId
        deliverAt: $deliverAt
      ) {
        _id
        ...productListInformation
      }
    }
  }
  ${productListInformationFragment}
`

export const getCategoriesPaginatedProductsCPG = gql`
  query getCategoriesLayoutPaginatedProductsCPG_cached(
    $categoryId: ID
    $menuId: ID
    $storeId: ID
    $deliverAt: Date
    $format: String
    $limit: Float
  ) {
    category(categoryId: $categoryId) {
      _id
      paginatedProducts(menuId: $menuId, storeId: $storeId, deliverAt: $deliverAt, limit: $limit) {
        items {
          _id
          modifiers {
            _id
            optional
          }
          categories {
            _id
            name
            internalName
          }
          ...productListInformation
        }
      }
    }
  }
  ${productListInformationFragment}
`
