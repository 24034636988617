import React from 'react'
import ClientSuspense from '@components/ClientSuspense'
import {useWebsiteMenuInformation} from '@data/queries/website/websiteMenuInformation.main'
import {useEffect} from '@hooks/useEffect'
import useIsStore from '@hooks/useIsStore'
import {useRef} from '@hooks/useRef'
import useUrlSearch from '@hooks/useUrlSearch'
import useURLState from '@hooks/useURLState'
import Container from '@packages/justo-parts/lib/components/Container'
import {useRouter} from 'next/router'

import WebsiteCoins from '../Products/ProductsContent/WebsiteCoins'
import useLayout from '../useLayout'

import Categories from './Categories'
import Loading from './Loading'
import Product from './Product'
import Search from './Search'
import SearchResults from './SearchResults'

import styles from './styles.module.css'

export default function CategoriesLayout() {
  const {website} = useWebsiteMenuInformation()

  const router = useRouter()

  const [categoryId, setCategoryId] = useURLState('cat')
  const layout = useLayout()
  const isStore = useIsStore()
  const showCategories = layout === 'mobile' && isStore
  const {filter, filterText, setFilter} = useUrlSearch()
  const categoryRef = useRef([])

  const scrollToCategory = categoryId =>
    setTimeout(
      () =>
        categoryRef.current[categoryId].scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        }),
      100
    )

  const handleChangeCategory = updateCategoryId => {
    if (isStore) {
      router.push(`/pedir/category/${updateCategoryId}`)
    } else {
      setCategoryId(updateCategoryId)
    }
  }

  useEffect(() => {
    if (filter && categoryId) {
      setCategoryId(null)
    }
  }, [filter])

  return (
    <div className={styles.container}>
      <div className={styles.searchBar}>
        <Container className={styles.containerWebsiteCoins}>
          <WebsiteCoins />
        </Container>
      </div>
      <div className={styles.searchBar}>
        <Search
          filter={filterText}
          setFilter={setFilter}
          showCategories={showCategories}
          website={website}
          scrollToCategory={scrollToCategory}
        />
      </div>
      <ClientSuspense fallback={<Loading />}>
        {filter ? (
          <SearchResults filter={filter} setFilter={setFilter} />
        ) : (
          <Categories
            categoryId={categoryId}
            setCategoryId={handleChangeCategory}
            categoryRef={categoryRef}
          />
        )}
      </ClientSuspense>
      <Product />
    </div>
  )
}
