import React from 'react'
import useLimit from '@page-components/Order/CategoriesLayout/Categories/Category/useLimit'
import range from 'lodash/range'

import Loading from './Loading'

import styles from './styles.module.css'

export default function Products(props) {
  const count = useLimit()
  return (
    <div className={styles.container}>
      {range(count).map(index => (
        <div className="col-xs-4 col-sm-3 col-md-2 col-lg-2" key={index}>
          <Loading />
        </div>
      ))}
    </div>
  )
}
