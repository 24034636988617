import React from 'react'
import {productListInformation} from '@data/fragments/products/__generated__/productListInformation'
import {useUserPreferences} from '@data/queries/preferences/userPreferences.main'
import useDebounce from '@hooks/useDebounce'
import useWebsiteId from '@hooks/useWebsiteId'
import Product from '@page-components/Order/Products/ProductsContent/Category/Product'
import cloneDeep from 'lodash/cloneDeep'

import ProductsLoading from '../../Loading/Products'

import NoResults from './NoResults'
import useProductSearchResults from './useProductSearchResults.main'

import styles from './styles.module.css'

export interface Props {
  filter: string
}

export default function Products(props: Props) {
  const {filter} = props
  const websiteId = useWebsiteId()
  const [debouncedFilter, debouncedLoading] = useDebounce(filter, 500)

  const data = useUserPreferences()
  const {menuId, storeId, deliverAt} = data

  const {queriedProducts, loading} = useProductSearchResults({
    storeId,
    deliverAt,
    menuId,
    websiteId,
    debouncedFilter
  })

  // We copy the products to be able to override read-only properties
  const products = queriedProducts ? queriedProducts.map(p => cloneDeep(p)) : null

  if (loading || debouncedLoading || !products) return <ProductsLoading />

  if (!products.length) return <NoResults />

  return (
    <div className={styles.container}>
      {products.map(product => {
        return (
          <div className="col-xs-4 col-sm-3 col-md-2 col-lg-2" key={product._id}>
            {/** TODO: Fix this type conversion */}
            <Product
              product={product as productListInformation}
              productsLayout="compact"
              menuId={menuId}
            />
          </div>
        )
      })}
    </div>
  )
}
