import React from 'react'
import Container from '@packages/justo-parts/lib/components/Container'

import Category from './Category'
import Products from './Products'

export default function Loading(props) {
  return (
    <Container>
      <Category>
        <Products />
      </Category>
    </Container>
  )
}
