import React from 'react'
import {useWebsiteMenuInformation} from '@data/queries/website/websiteMenuInformation.main'
import {FirstCategoryContext} from '@helpers/websites/FirstCategoryContext'

import Category from '../Category'
import MultiBrandBanner from '../MultiBrandBanner'

export interface Props {
  categories: any
  setCategoryId: (categoryId: string) => void
  categoryRef: any
}

export default function HighlightedCategories(props: Props) {
  const {categories, setCategoryId: setSelectedCategoryId, categoryRef} = props

  const {website} = useWebsiteMenuInformation()

  const {isMultiBrand, categoryLayoutForMultiBrand} = website

  return isMultiBrand && categoryLayoutForMultiBrand ? (
    <MultiBrandBanner setSelectedCategoryId={setSelectedCategoryId} categoryRef={categoryRef} />
  ) : (
    categories?.items?.map((category, index) => (
      <FirstCategoryContext.Provider value={index === 0} key={category._id}>
        <Category category={category} setSelectedCategoryId={setSelectedCategoryId} />
      </FirstCategoryContext.Provider>
    ))
  )
}
