import React from 'react'

import styles from './styles.module.css'

export default function Category(props) {
  return (
    <div className={styles.container}>
      <div className={styles.header} />
      {props.children}
    </div>
  )
}
