import {
  GetCategoriesLayoutPaginatedProductsCpg_CachedQuery,
  GetCategoriesLayoutPaginatedProductsCpg_CachedQueryVariables
} from '@data/__generated__/types.main'
import hasWebpSupport from '@helpers/misc/hasWebpSupport'
import {useApolloQuery} from 'apollo-hooks'

import {getCategoriesPaginatedProductsCPG} from './queries/categoryLayoutProductsQueries.main'

export interface UseProductsCPGOpts {
  categoryId?: string
  storeId?: string
  deliverAt?: string
  menuId?: string
  full?: boolean
  omit?: boolean
}

export default function useProductsCPG({
  categoryId,
  storeId,
  deliverAt,
  menuId,
  full,
  omit
}: UseProductsCPGOpts) {
  const format = hasWebpSupport() ? 'webp' : 'jpeg'
  const isInsideSelectedCategory = full

  const variables = {
    categoryId,
    menuId,
    storeId,
    deliverAt,
    format,
    limit: isInsideSelectedCategory ? null : 10
  }

  const {category, loading} = useApolloQuery<
    GetCategoriesLayoutPaginatedProductsCpg_CachedQuery,
    GetCategoriesLayoutPaginatedProductsCpg_CachedQueryVariables
  >({
    query: getCategoriesPaginatedProductsCPG,
    variables,
    omit,
    fetchPolicy: 'cache-first'
  })

  if (omit) return []

  const categoriesProducts = category?.paginatedProducts?.items
  if (loading) return []

  return categoriesProducts.map(product => ({...product})) ?? []
}
