import React from 'react'
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md'
import ClientSuspense from '@components/ClientSuspense'
import {useWebsiteMenuInformation} from '@data/queries/website/websiteMenuInformation.main'
import useProductsLayout from '@page-components/Order/useProductsLayout'
import classNames from 'classnames'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'

import ProductsLoading from '../../Loading/Products'

import Products from './Products'
import useProducts from './useProducts'

import styles from './styles.module.css'

export interface Props {
  category: any
  setSelectedCategoryId?: (categoryId: string) => void
  full?: boolean
}

function CategoryInner(props: Props) {
  const {website} = useWebsiteMenuInformation()
  const {category, setSelectedCategoryId, full} = props

  const productsLayout = useProductsLayout(website)
  const isCategoriesLayout = productsLayout === 'categories'
  const products = useProducts({
    categoryId: category._id,
    full
  })

  const {t} = useTranslation('generic')
  const router = useRouter()
  const isOpenCategory = !!router.query.cat

  if (!products.length) return null

  return (
    <>
      <div className={styles.header}>
        <div className={styles.name}>{category.name}</div>
        <div
          className={styles.button}
          onClick={() => setSelectedCategoryId(full ? null : category._id)}>
          {full ? (
            <span>
              <MdKeyboardArrowLeft size={20} /> {t('fields.goBack')}
            </span>
          ) : (
            <span>
              {t('fields.showAll')} <MdKeyboardArrowRight size={20} />
            </span>
          )}
        </div>
      </div>

      <div
        className={classNames(
          isCategoriesLayout && products.length > 2 ? 'overflow-x-auto' : 'overflow-x-hidden'
        )}>
        <Products
          isOpenCategory={isOpenCategory}
          isCategoriesLayout={isCategoriesLayout}
          categoryId={category._id}
          full={full}
          categoryName={category.name}
        />
      </div>
    </>
  )
}

export default function Category(props: Props) {
  return (
    <div className={styles.container}>
      <ClientSuspense fallback={<ProductsLoading />}>
        <CategoryInner {...props} />
      </ClientSuspense>
    </div>
  )
}
