import React from 'react'
import {useTranslation} from 'next-i18next'

import styles from './styles.module.css'

export default function NoResults(props) {
  const {t} = useTranslation('website')
  return (
    <div className={styles.container}>
      {t('order.categoriesLayout.searchResults.products.labelNoResult')}
    </div>
  )
}
