import React from 'react'
import {FirstCategoryContext} from '@helpers/websites/FirstCategoryContext'
import useWebsiteId from '@hooks/useWebsiteId'
import Container from '@packages/justo-parts/lib/components/Container'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

import Category from './Category'
import HighlightedCategories from './HighlightedCategories'

import styles from './styles.module.css'

export interface Props {
  categoryId: string
  setCategoryId: (categoryId: string) => void
  categoryRef: any
}

export default function Categories(props: Props) {
  const {categoryId: selectedCategoryId, setCategoryId: setSelectedCategoryId} = props

  const websiteId = useWebsiteId()
  const {categories} = useApolloQuery({
    query: gql`
      query categoriesLayoutCategories($websiteId: ID) {
        categories(websiteId: $websiteId) {
          items {
            _id
            name
          }
        }
      }
    `,
    variables: {websiteId}
  })

  const selectedCategory = selectedCategoryId
    ? categories.items.find(category => category._id === selectedCategoryId)
    : null

  return (
    <div className={styles.container}>
      <Container>
        {selectedCategory ? (
          <FirstCategoryContext.Provider value={true}>
            <Category
              full
              category={selectedCategory}
              setSelectedCategoryId={setSelectedCategoryId}
            />
          </FirstCategoryContext.Provider>
        ) : (
          <HighlightedCategories categories={categories} {...props} />
        )}
      </Container>
    </div>
  )
}
