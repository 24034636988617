import {FirstCategoryContext} from '@helpers/websites/FirstCategoryContext'
import useCategoriesBrands from '@hooks/useCategoriesBrands.main'
import useWebsiteId from '@hooks/useWebsiteId'
import Category from '@page-components/Order/CategoriesLayout/Categories/Category'
import BrandBar from '@page-components/Order/Products/ProductsContent/BrandBar'
import NoBrandDivider from '@page-components/Order/Products/ProductsContent/NoBrandDivider'
import React from 'react'

export interface Props {
  setSelectedCategoryId: (categoryId: string) => void
  categoryRef: any
}

export default function MultiBrandBanner(props: Props) {
  const {setSelectedCategoryId, categoryRef} = props
  const websiteId = useWebsiteId()

  const categories = useCategoriesBrands(websiteId)

  if (!categories) return null

  let lastBrandId = null

  const components = categories?.items?.map((category, index) => {
    let brand = null
    if (category.brand && category.brand._id !== lastBrandId) {
      brand = category.brand
      lastBrandId = brand._id
    }

    const dividerContent =
      !category.brand && lastBrandId ? <NoBrandDivider key={category._id} /> : null
    const brandContent = brand ? <BrandBar key={category._id} brand={brand} /> : null

    if (!category.brand && lastBrandId) {
      lastBrandId = null
    }

    return (
      <div key={category._id}>
        {brandContent}
        {dividerContent}
        <div
          ref={ref => {
            categoryRef.current[category._id] = ref
          }}
        >
          <FirstCategoryContext.Provider value={index === 0}>
            <Category
              key={category._id}
              category={category}
              setSelectedCategoryId={setSelectedCategoryId}
            />
          </FirstCategoryContext.Provider>
        </div>
      </div>
    )
  })

  return <>{components}</>
}
