import useIsStore from '@hooks/useIsStore'

import useProductsCPG, {UseProductsCPGOpts} from './useProductsCPG'
import useProductsNonCPG, {UseProductsNonCPGOpts} from './useProductsNonCPG'

export default function useProducts(opts: UseProductsCPGOpts | UseProductsNonCPGOpts) {
  const isStore = useIsStore()

  const productsCPG = useProductsCPG({...opts, omit: !isStore})
  const productsNonCPG = useProductsNonCPG({...opts, omit: isStore})

  return isStore ? productsCPG : productsNonCPG
}
