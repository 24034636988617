import {
  GetCategoriesLayoutProductSearchResults_CachedQuery,
  GetCategoriesLayoutProductSearchResults_CachedQueryVariables
} from '@data/__generated__/types.main'
import productListInformationFragment from '@data/fragments/products/productListInformation.main'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export interface Props {
  storeId?: string
  deliverAt?: Date
  menuId?: string
  websiteId?: string
  debouncedFilter?: string
}

export default function useProductSearchResults(props: Props) {
  const {storeId, deliverAt, menuId, websiteId, debouncedFilter} = props

  const {products, loading} = useApolloQuery<
    GetCategoriesLayoutProductSearchResults_CachedQuery,
    GetCategoriesLayoutProductSearchResults_CachedQueryVariables
  >({
    query: gql`
      query getCategoriesLayoutProductSearchResults_cached(
        $websiteId: ID
        $filter: String
        $menuId: ID!
        $format: String
        $storeId: ID
        $deliverAt: Date
      ) {
        products: searchProducts(
          filter: $filter
          websiteId: $websiteId
          menuId: $menuId
          storeId: $storeId
          deliverAt: $deliverAt
        ) {
          _id
          ...productListInformation
        }
      }
      ${productListInformationFragment}
    `,
    variables: {
      websiteId,
      filter: debouncedFilter,
      menuId,
      storeId,
      deliverAt
    },
    partial: true,
    omit: !menuId
  })

  return {queriedProducts: products, loading}
}
