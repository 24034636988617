import Container from '@packages/justo-parts/lib/components/Container'
import {useTranslation} from 'next-i18next'
import {MdKeyboardArrowLeft} from 'react-icons/md'

import Products from './Products'

import styles from '../Categories/Category/styles.module.css'

export interface Props {
  filter: string | null
  setFilter: (filter: string | null) => void
}

export default function Category(props: Props) {
  const {setFilter, filter} = props
  const {t} = useTranslation('generic', {keyPrefix: 'fields'})
  return (
    <div className={styles.mainContainer}>
      <Container>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.name}>{t('result')}</div>
            <div className={styles.button} onClick={() => setFilter('')}>
              <span>
                <MdKeyboardArrowLeft size={20} /> {t('goBack')}
              </span>
            </div>
          </div>
          <Products filter={filter} />
        </div>
      </Container>
    </div>
  )
}
