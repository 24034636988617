import React from 'react'
import getQueryObject from '@helpers/misc/getQueryObject'
import SelectProduct from '@page-components/Order/Products/ProductsContent/SelectProduct'
import {useSelectedProductId} from '@page-components/OrderV2/useSelectedProduct'
import {useRouter} from 'next/router'

interface Props {
  productId?: string
}

export default function Product() {
  const router = useRouter()
  const productId = useSelectedProductId()

  return (
    <SelectProduct
      product={{_id: productId}}
      open={!!productId}
      allowEdit={false}
      close={() => {
        const query = getQueryObject(router)
        delete query.productId
        router.push({pathname: '/pedir', query}, null, {scroll: false})
      }}
    />
  )
}
