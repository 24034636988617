import {useUserPreferences} from '@data/queries/preferences/userPreferences.main'
import hasWebpSupport from '@helpers/misc/hasWebpSupport'
import useEventsBaseProperties, {UseEventsBaseProperties} from '@hooks/useEventsBaseProperties'
import useIsStore from '@hooks/useIsStore'
import useInitialData from '@page-components/Layout/useInitialData'
import Product from '@page-components/Order/Products/ProductsContent/Category/Product'
import useProductsLayout from '@page-components/Order/useProductsLayout'
import classNames from 'classnames'
import React from 'react'

import useProductsCPG from '../useProductsCPG'
import useProductsNonCPG from '../useProductsNonCPG'

import styles from './styles.module.css'

const format = hasWebpSupport() ? 'webp' : 'jpeg'

const ProductsCPG = (props: {
  categoryId?: string
  storeId?: string
  deliverAt?: string
  menuId?: string
  full?: boolean
  categoryName: string
  eventBaseProperties: UseEventsBaseProperties
}) => {
  const {categoryId, categoryName, storeId, deliverAt, menuId, full, eventBaseProperties} = props
  const products = useProductsCPG({
    categoryId,
    storeId,
    deliverAt,
    menuId,
    full,
  })

  return (
    <>
      {products.map(product => (
        <div className="col-xs-6 col-sm-3 col-md-2 col-lg-2" key={product._id}>
          <Product
            product={product}
            productsLayout="compact"
            menuId={menuId}
            categoryName={categoryName}
            eventBaseProperties={eventBaseProperties}
          />
        </div>
      ))}
    </>
  )
}

const ProductsNonCPG = (props: {
  categoryId: string
  menuId: string
  full: boolean
  categoryName: string
  eventBaseProperties: UseEventsBaseProperties
}) => {
  const {categoryId, categoryName, menuId, full, eventBaseProperties} = props

  const products = useProductsNonCPG({
    categoryId,
    full,
  })

  return (
    <>
      {products.map(product => (
        <div className="col-xs-6 col-sm-3 col-md-2 col-lg-2" key={product._id}>
          <Product
            product={product}
            productsLayout="compact"
            menuId={menuId}
            categoryId={categoryId}
            categoryName={categoryName}
            eventBaseProperties={eventBaseProperties}
          />
        </div>
      ))}
    </>
  )
}

export interface Props {
  categoryId: string
  isOpenCategory: boolean
  isCategoriesLayout: boolean
  full: boolean
  categoryName?: string
}

export default function Products(props: Props) {
  const {website} = useInitialData()
  const productsLayout = useProductsLayout(website)
  const isCategoriesLayout = productsLayout === 'categories'
  const data = useUserPreferences()
  const {menuId, deliverAt, storeId} = data

  const {isOpenCategory, full, categoryId, categoryName = ''} = props

  const isStore = useIsStore()
  const eventBaseProperties = useEventsBaseProperties()

  return (
    <div
      className={classNames(
        isCategoriesLayout ? styles.categoryOverflow : styles.container,
        isOpenCategory ? styles.open : '',
      )}
    >
      {isStore ? (
        <ProductsCPG
          categoryId={categoryId}
          deliverAt={deliverAt}
          full={full}
          menuId={menuId}
          storeId={storeId}
          categoryName={categoryName}
          eventBaseProperties={eventBaseProperties}
        />
      ) : (
        <ProductsNonCPG
          categoryId={categoryId}
          full={full}
          menuId={menuId}
          categoryName={categoryName}
          eventBaseProperties={eventBaseProperties}
        />
      )}
    </div>
  )
}
